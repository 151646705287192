function NavbarProcess(handler) {
    var me = this;
    this.logoutUrl = dataActive.location.marketplace + "/v1/logout";
    this.logout = function () {
        var userToken = getStorage("loginInformation").token;
        if (userToken !== undefined) {
            ajax({
                url: me.logoutUrl,
                method: "get",
                headers: { 'Authorization': 'Bearer ' + getStorage("loginInformation").token },
                data: "",
                success: function (data) {
                    if (data.userFlag == 'registerType:socialMedia,by:Facebook') {
                        FB.logout();
                    }
                    localStorage.removeItem("loginInformation" + Constants.ver);
                    if (getStorage("redirectAfterLogin") !== "") {
                        localStorage.removeItem("redirectAfterLogin");
                        dataActive.pageRoute("index");
                        return;
                    }
                    location.reload();
                },
            });
        } else {
            if (getStorage("redirectAfterLogin") !== "") {
                localStorage.removeItem("redirectAfterLogin");
                dataActive.pageRoute("index");
                return;
            }
            location.reload();
        }
    }
    
    if($(window).width() > 1200) {
        $('.header-travel-ae').addClass('desktop')
    }
    else {
        $('.header-travel-ae').addClass('mobile')
    }

    handler.domNode.find(".onClick-currency").click((e) => {
        e.stopPropagation();
        handler.domNode.find(".onClick-currency").toggleClass('open')
        handler.domNode.find(".onClick-currency").next().slideToggle("slow");
    });
  
    handler.domNode.find(".onClick-language").click((e) => {
        e.stopPropagation();
        handler.domNode.find(".onClick-language").toggleClass('open')
        handler.domNode.find(".onClick-language").next().slideToggle("slow");
    });
    //? handle event in new navbar
    handler.domNode.find(".onClick-currency-navbar").click((e) => {
        e.stopPropagation();
        handler.domNode.find(".onClick-currency-navbar").toggleClass('open')
        handler.domNode.find(".onClick-currency-navbar").next().slideToggle("slow"); 
        handler.domNode.find('.onClick-currency-navbar').find('i').toggleClass('fa-caret-up', 'fa-caret-down');
        handler.domNode.find('.onClick-language-navbar').removeClass('open').next().hide();
        handler.domNode.find('.onClick-language-navbar').find('i').removeClass('fa-caret-up').addClass('fa-caret-down');
    });
  
    handler.domNode.find(".onClick-language-navbar").click((e) => {
        e.stopPropagation();
        handler.domNode.find(".onClick-language-navbar").toggleClass('open')
        handler.domNode.find(".onClick-language-navbar").next().slideToggle("slow");
        handler.domNode.find('.onClick-language-navbar').find('i').toggleClass('fa-caret-up', 'fa-caret-down');
        handler.domNode.find('.onClick-currency-navbar').removeClass('open').next().hide();
        handler.domNode.find('.onClick-currency-navbar').find('i').removeClass('fa-caret-up').addClass('fa-caret-down');
    });

    $('html').on('click', (e) => {
        let onClickCurrency = handler.domNode.find(".onClick-currency-navbar");
        let onClickLang = handler.domNode.find(".onClick-language-navbar");
        if(onClickCurrency.next().has(e.target).length == 0) {
            onClickCurrency.removeClass('open').next().hide();
            onClickCurrency.find('i').removeClass('fa-caret-up').addClass('fa-caret-down');
            onClickLang.removeClass('open').next().hide();
            onClickLang.find('i').removeClass('fa-caret-up').addClass('fa-caret-down');
        }
    })

    this.checkLogin = function () {
        var loginInformation = getStorage("loginInformation");
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var check = false;
            ajax({
                url: dataActive.location.marketplace + "/v1/checkuserlogin",
                headers: { 'Authorization': 'Bearer ' + loginInformation.token },
                method: "get",
                async: false,
                success: function (data) {
                    check = data.status;
                },
                error: function () { check = false; }
            });
            if (check) {
                me.getUserInfo();
                return;
            } else {
                localStorage.removeItem("loginInformation" + Constants.ver);
            }
        }
    }

    this.changeCurrency = function () {
        dataActive.updateConfig('currentCurrency', getConfig('currentCurrency', getConfig('currency')));
    }

    this.changeCalendar = function () {
        dataActive.updateConfig('currentCalendar', (dataActive.location.query.lang == 'FA' ? 'jalali' : 'gregorian'));
    }

    this.getUserInfo = function () {
        var token = getStorage("loginInformation").token;
        ajax({
            url: dataActive.location.marketplace + "/v1/user/info",
            method: 'get',
            data: {
                api_token: token,
            },
            headers: { 'Authorization': 'Bearer ' + token },
            success: function (data) {
                setStorage("loginInformation", data);
                me.mapUserInformation(data);
            },
            error: function () {
                // lug.error('We could not update the user information.');
            }
        });
    }

    this.mapUserInformation = function (data) {
        handler.domNode.find(".sign-in-template").replaceWith(
            view('partials/login-information', {
                userInfo: data.user_info,                
                data: data
            }
            ));
        handler.domNode.find(".sing-out-button").click(me.logout);
        handler.domNode.find(".onClick-currency").click((e) => {
            e.stopPropagation();
            handler.domNode.find(".onClick-currency").next().slideToggle("slow");
        });
      
        handler.domNode.find(".onClick-language").click((e) => {
            e.stopPropagation();
            handler.domNode.find(".onClick-language").next().slideToggle("slow");
        });

        // handler.domNode.find('.ul-user-menu-nav').click((e) => e.stopPropagation())

        handler.domNode.find('.node-language').each((index, item) => {
            $item = $(item);
            $item.on('click', function () {
                dataActive.$head.find('.font-style').remove();
                var language = $(this).data('value');
                language = dataActive.hooks.call('changeLanguage', language);
                if (!$.isEmptyObject(language)) {
                    let currentLang =  dataActive.location.query.lang;
                    dataActive.location.query.lang = language.iso_code;
                    me.changeCalendar();
                    dataActive.siteLanguage();             
                    if(dataActive.location.address.includes('voucher') || dataActive.location.address.includes('ticket')){
                        let address = dataActive.location.address;
                        let href = address.replace(currentLang, language.iso_code);
                        window.location.href= href;
                    } else {
                        dataActive.pageRoute('index');
                    }
                }
            });
        });
        handler.domNode.find('.node-currency').each((index, item) => {
            $item = $(item);
            $item.on('click', function () {
                me.changeCurrency();
                var currency = $(this).data('value');
                currency = dataActive.hooks.call('changeCurrency', currency);
                if (!$.isEmptyObject(currency)) {
                    dataActive.updateConfig('currentCurrency', currency);
                    dataActive.pageRoute('index');
                }
            });
        });
      
    }

    ///> init
    this.checkLogin();
    var activeUrl = window.location.pathname;
    $('a[href="' + activeUrl + '"]').parent('li').addClass('active');
    handler.domNode.find('.node-language').each((index, item) => {
        $item = $(item);
        $item.on('click', function () {
            dataActive.$head.find('.font-style').remove();
            var language = $(this).data('value');
            language = dataActive.hooks.call('changeLanguage', language);
            if (!$.isEmptyObject(language)) {
                let currentLang =  dataActive.location.query.lang;
                dataActive.location.query.lang = language.iso_code;
                me.changeCalendar();
                dataActive.siteLanguage();
                if(dataActive.location.address.includes('voucher') || dataActive.location.address.includes('ticket')){
                    let address = dataActive.location.address;
                    let href = address.replace(currentLang, language.iso_code);
                    window.location.href= href;
                } else {
                    dataActive.pageRoute('index');
                }
            }
        });
    });
    handler.domNode.find('.node-currency').each((index, item) => {
        $item = $(item);
        $item.on('click', function () {
            me.changeCurrency();
            var currency = $(this).data('value');
            currency = dataActive.hooks.call('changeCurrency', currency);
            if (!$.isEmptyObject(currency)) {
                dataActive.updateConfig('currentCurrency', currency);
                dataActive.pageRoute('index');
            }
        });
    });
    dataActive.hooks.register('beforePageRoute', function (configs) {
        dataActive.hooks.clear('changeCurrency');
        dataActive.hooks.clear('changeLanguage');
        return configs;
    });
}