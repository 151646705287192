function FlightSearchForm($form) {

    dataActive.hooks.clear('afterAjaxError');
    var searchButton = $form.find('.service-search-btn');
    var airportRecomendedName = "airportRecomended" + dataActive.location.query.lang;
    var airports = [];
    var airportId = [];
    const returnDateInput = $(view("flight/return-date-input"));
    var me = this;

    function getFields() {
        var data = {};
        $form.find("[name][type!=radio],[type=radio]:checked,[type=checkbox]:checked").each(function (index, element) {
            $form.find('.trip-type>input').each((index,item) => {
                if((item.value == 'oneWay' && item.checked) || (item.value == 'multiDestination' && item.checked)) {
                    $(returnDateInput).find("input").each((index,input) => input.value = "");
                }
            })
            var $element = $(element);
            var elementName = $element.attr('name');
            data[elementName] = $element.val();
            if($element.hasClass('main-charterOnly')) {
                if($element.prop('checked'))
                    data[$element.attr('name')] = true;
                else
                    data[$element.attr('name')] = false;
            }
        });
        if(data.tripType == 'roundTrip' || data.tripType == 'openReturn') {
            data["legs[1][origin]"]      = data["legs[0][destination]"]
            data["legs[1][destination]"] = data["legs[0][origin]"]
        }
        return data;
    }

    function success(data) {
        var sessionId = data.sessionId || "";
        // lug.info("sessionId", { message: data });
        if (String(sessionId).length > 0) {
            dataActive.pageRoute("flightResult", data);
        } else {
            dataActive.showErrors(trans("error happend . try again later."));
        }
    }
    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }
    this.ajaxParams = function () {
        me.setRecomenededAirportsInLocal();
        return {
            url: dataActive.location.marketplace + "/v2/search/flight",
            method: "post",
            headers: headers || {},
            data: getFields(),
            success: success,
            error: function (data, validation) {
                me.enabelButton();
                dataActive.defaultAjaxError(data, validation);
            },
        };
    }

    function tripType() {
        $form.find(".one-way").click(function (e) {
            selectTripType($(this));
            const additionalForm = $form.find(".normal-flight-form-search");
            $(additionalForm).filter(":gt(0)").remove();
            // $(additionalForm).removeClass("col-md-12").addClass("col-md-8");
            $(additionalForm).find(".row").removeClass("col-md-11").addClass('col-md-12');
            $(additionalForm).find(".delete-trip").hide()
            const startDateInput = $form.find('.start-flight-date');
            $(startDateInput).data('triptype','oneWay');
            $form.find(".datePickerReturn").val("");
            $(returnDateInput).remove();
            $(startDateInput).removeClass('col-md-2 col-sm-6 col-xs-6').addClass('col-md-4 col-xs-12').find('.border-search-fields').removeClass('rounded-l-8');
            $form.find('.add-trip-button').hide();
            checkTriptype(e.currentTarget);
        });
        $form.find(".round-trip").click(function (e) {
            selectTripType($(this));
            const additionalForm = $form.find(".normal-flight-form-search");
            $(additionalForm).filter(":gt(0)").remove();
            $(additionalForm).find(".row").removeClass("col-md-11").addClass('col-md-12');
            $(additionalForm).find(".delete-trip").hide();
            const startDateInput = $form.find('.start-flight-date');
            $(startDateInput).data('triptype','roundTrip');
            const datepicker = $(returnDateInput).find(".datepicker-input");
            $(startDateInput).removeClass("col-md-4 col-xs-12").addClass("col-md-2 col-xs-6");
            $(startDateInput).after(returnDateInput);
            $form.find('.add-trip-button').hide();
            checkTriptype(e.currentTarget);
            if($(datepicker).nextAll().length >= 2) {
                $(datepicker).next().remove();
            }
            var fliterButton = $form.closest('.theme-hero-area').next().find('#mobileFilters');
            ChooseCalendar(datepicker, fliterButton, false);
        });
        $form.find('.multi-destination').click(function (e) {
            selectTripType($(this));
            const startDateInput = $form.find('.start-flight-date');
            $(startDateInput).data('triptype','multiDetination');
            $form.find(".datePickerReturn").val("");
            $form.find('.add-trip-button').show();
            $(returnDateInput).remove();
            $(startDateInput).removeClass('col-md-2 col-xs-6 col-sm-6').addClass('col-md-4 col-xs-12').find('.border-search-fields').removeClass('rounded-l-8');
            const newTrip = $(view("flight/flight-inputs-search-from"));
            if($form.find('.normal-flight-form-search').length < 2) {
                $form.find('.normal-flight-form-search').after(newTrip);
                deletedItem();
            }
            $($form.find('.normal-flight-form-search')[0]).find(".delete-trip").show();
            $($form.find('.normal-flight-form-search')).find(".row").addClass("col-md-11");
            dynamicName(newTrip);
            $(newTrip).find(".shift-key").on("click",shiftKey);
            checkTriptype(e.currentTarget);
        });
        $form.find('.open-return').click(function(e) {
            const additionalForm = $form.find(".normal-flight-form-search");
            $form.find(".datePickerReturn").val("");
            $(returnDateInput).remove();
            $(additionalForm).filter(":gt(0)").remove();
            $(additionalForm).removeClass("col-md-12").addClass("col-md-8");
            $(additionalForm).find(".row").removeClass("col-md-8");
            $(additionalForm).find(".delete-trip").hide();
            const startDateInput = $form.find('.start-flight-date');
            $(startDateInput).find('input.start-date').val("");
            $(startDateInput).data('triptype','openReturn');
            $(startDateInput).removeClass("col-md-6 col-xs-12").addClass("col-md-3 col-xs-6");
            $(startDateInput).after(returnDateInput);
            $form.find('.add-trip-button').hide();
            checkTriptype(e.currentTarget);
        });
    }

    this.setRecomenededAirportsInLocal = function() {
        if(!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var airportsTitle = $form.find(".wrapper-btn");
            if(localStorage.getItem(airportRecomendedName) == null) {
                airportsTitle.each((id,title) => {
                    if($(title).siblings(".custom-select").find("input[type=hidden]").val() !== "") {
                        airports.push({value: $(title).siblings(".custom-select").find("input[type=hidden]").val(), title: title.innerText });
                        localStorage.setItem(airportRecomendedName , JSON.stringify(airports));
                    }
                });
            } else {
                airports = JSON.parse(localStorage.getItem(airportRecomendedName));
                airports.forEach(airport => airportId.push(airport.value));
                airportsTitle.each((i,title) => {
                    if($(title).siblings(".custom-select").find("input[type=hidden]").val() !== "") {
                        if(!airportId.includes($(title).siblings(".custom-select").find("input[type=hidden]").val())) {
                            if(airports.length == 5) {
                                var length = airports.length;
                                airports.splice((length - 1),1);
                                airports.unshift({value: $(title).siblings(".custom-select").find("input[type=hidden]").val(), title: title.innerText});
                                localStorage.setItem(airportRecomendedName , JSON.stringify(airports));
                            } else {
                                airports.unshift({value: $(title).siblings(".custom-select").find("input[type=hidden]").val(), title: title.innerText});
                                localStorage.setItem(airportRecomendedName , JSON.stringify(airports));
                            }
                        }
                    }
                })
            }
        }
    }

    this.disableButton = function () {
        searchButton.prop('disabled', true);
    }

    this.enabelButton = function () {
        searchButton.prop('disabled', false);
    }

    // multi destinations logic:
    $form.find('.add-trip-button').on('click', function () {
        const length = $form.find('.normal-flight-form-search').length;
        const formFlight = $form.find('.normal-flight-form-search');
        const newTrip = $(view("flight/flight-inputs-search-from"));
        if(length < 4) {
            $($(formFlight)[length-1]).after(newTrip);
            $($(formFlight)[length-1]).addClass("col-md-12").find('.delete-trip').show();
            $($(formFlight)[length-1]).find(".row").addClass("col-md-11");
            dynamicName(newTrip);
        }
        $(newTrip).find(".row").addClass("col-md-11").find(".shift-key").on("click",shiftKey);
        deletedItem();
    });

    function dynamicName(newTrip) {
        $form.find('.normal-flight-form-search').each((index,item) => {
            const $inputs = $(item).find('input');
            $inputs.each((i,input) => {
                const type = $(input).attr('data-type');
                if(type) {
                    $(input).attr("data-name",`legs[${index}][${type}]`);
                    if(type == 'departure' || type == "departureShamsiDate"){
                        $(input).attr("name",`legs[${index}][${type}]`);
                    }
                }
            })
        });
        customer(newTrip);
    }

    function customer(newTrip) {
        const airport = $(newTrip).find('.select-airport');
        const datepicker = $(newTrip).find('.datepicker-input');
        var fliterButton = $form.closest('.theme-hero-area').next().find('#mobileFilters');
        ChooseCalendar(datepicker,fliterButton, false);
        new CustomSelect(airport, { url: dataActive.location.marketplace + '/v1/search/airports' + '?lang=' + dataActive.location.query.lang.toLowerCase(), message: 'Search_by_city_or_airport' });
        // FlightQuantitySelector($form.find('.flight-quantity-selector-input').data("initial"));
    }

    function checkTriptype(target) {
        const $thisTriptype = $(target);
        $form.find("li.trip-type").each((inedex,li) => {
            $(li).find('input:checked').removeAttr('checked');
        })
        $thisTriptype.find('input').attr('checked','checked');
    }

    function deletedItem() {
        $form.find('.delete-trip-button').each((index,item) => {
            $(item).click(() => {
                $(item).parent().parent().parent().remove();
                $form.find('.trip-number').each((ind,item) => {
                    const $inputs = $(item).find('input');
                    $inputs.each((i,input) => {
                        if(input.name.includes('legs')) {
                            let name = $(input).attr("name");
                            let dataName;
                            if($(input).attr('data-name')) {
                                 dataName = $(input).attr("data-name")
                                 dataName = dataName.replace(/\d/g,ind)
    
                                $(input).attr("data-name",dataName)
                            }
                            name = name.replace(/\d/g,ind)
                          
                            $(input).attr("name",name)
                        } else if($(input).data("name")) {
                            dataName = $(input).attr("data-name")
                            dataName = dataName.replace(/\d/g,ind)
                            $(input).attr("data-name",dataName)
                        }
                    })
                })
            })
        })
    }
   

    ///> init
    tripType();
    deletedItem();
    // $form.find('.flight-search-form-items').prepend($(oneWayForm));
    $('.shift-key').on("click",shiftKey)
    function shiftKey(e) {
        const shift = $(e.currentTarget);
        $(shift).toggleClass('rotate')
        let origin = $(shift).prev().find('.select-airport');
        let destination = $(shift).next().find('.select-airport');
        let originValue = $(origin).prev().find('input[type="hidden"]');
        let destinationValue = $(destination).prev().find("input[type='hidden']");
        let originTitle = $(origin).prev().prev();
        let destinationTitle = $(destination).prev().prev();
        // if both inputs are fill:
        if(originValue.val() !== "" && destinationValue.val() !== "") {
            // in the first step we have to store old values to access to them in the next step:
            const newOrgVal = destinationValue.val();
            const newDesVal = originValue.val();
            const newOrgTitle = destinationTitle.prev().html();
            const newDesTitle = originTitle.prev().html();
            // in this step we wanna access to old values:
            destinationValue.val(newDesVal);
            originValue.val(newOrgVal);
            destinationTitle.prev().html(newDesTitle);
            originTitle.prev().html(newOrgTitle);
        }
        // if one of the inputs is fill:
        else if (originValue.val() !== "" && destinationValue.val() === "") {
            const newDesVal = originValue.val();
            const newDesTitle = originTitle.prev().html();
            destinationValue.val(newDesVal);
            destinationTitle.html(newDesTitle).css("width","90%").after('<i class="fa fa-times clear-selected" data-title="Destination"></i>');
            originValue.val("");
            originTitle.prev().html('<i class="theme-search-area-section-icon lin lin-location-pin"></i>'+trans("Origin")).next().remove();
        }
        else if(destinationValue.val() !== "" && originValue.val() === "") {
            const newOrgVal = destinationValue.val();
            const newOrgTitle = destinationTitle.prev().html();
            originValue.val(newOrgVal);
            originTitle.html(newOrgTitle).css("width","90%").after('<i class="fa fa-times clear-selected" data-title="Origin"></i>');
            destinationValue.val("");
            destinationTitle.prev().html('<i class="theme-search-area-section-icon lin lin-location-pin"></i>'+trans("Destination")).next().remove();
        }
        origin.parent().find(".clear-selected").on("click",clearInput);
        destination.parent().find(".clear-selected").on("click",clearInput);
    }

    function clearInput(e) {
        $(e.currentTarget).prev().html("").html('<i class="theme-search-area-section-icon lin lin-location-pin"></i>'+trans($(e.currentTarget).data("title")));
        $(e.currentTarget).next().find("input[type='hidden']").val('');
        $(e.currentTarget).remove();
    }

    $form.find('.datePickerDeparture').prev().on('click', function () {
        $form.find('.datePickerDeparture').focus();
    });

    $form.find('.datePickerReturn').prev().on('click', function () {
        $form.find('.datePickerReturn').focus();
    });


    $form.find('.datePickerDeparture').on('focus', function (e) {
        e.preventDefault();
        $(this).attr("autocomplete", "off");
    });
    $form.find('.datePickerReturn').on('focus', function (e) {
        e.preventDefault();
        $(this).attr("autocomplete", "off");
    });
    FlightQuantitySelector($form.find('.flight-quantity-selector-input').data("initial"));
    $form.find('.searcherIdentity').val(localStorage.getItem('searcherIdentity'));

    $($form.find('.lin-people')).on('click', function () {
        $(this).siblings().click();
    });
    $form.find(".dropdown-menu li").each((index, item) => {
        $(item).on('click', function () {
            $(item).parent().prev().find('span').text($(this).text());
        })
    });
    $form.find('.classes-list-drop-down li').each((index, item) => {
        $(item).on('click', function () {
            $form.find('.classes-list-drop-down li').each((index,li) => $(li).removeClass('class-selected'));
            $(item).parent().prev().find('span').text($(this).text());
            $(item).addClass('class-selected');
        })
    });
    $form.find('.datePickerDeparture').change(function () {
        if ($('input[name=tripType]:checked').val() == 'roundTrip') {
            $form.find('.datePickerReturn').val($(this).val());
            setTimeout(() => { 
                $form.find('.datePickerReturn').click();
                $form.find('.datePickerReturn').prev().prev().click();
            }, 100);
        }
    });
    var fliterButton = $form.closest('.theme-hero-area').next().find('#mobileFilters');
    var selectAirport = $form.find('.select-airport');
    new CustomSelect(selectAirport, { url: dataActive.location.marketplace + '/v1/search/airports' + '?lang=' + dataActive.location.query.lang.toLowerCase(), message: 'Search_by_city_or_airport' });

    var datepicker = $form.find('.datepicker-input');
    ChooseCalendar(datepicker, fliterButton, false);

    function selectTripType(item) {
        $form.find('.trip-type-section label').each((index,label) => $(label).removeClass('checked'));
        $(item).addClass('checked');
    }
}