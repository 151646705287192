function PayForm(data) {
    var $form = data.payForm;
    var isManual = data.isManual || false;
    var process = data.process;
    var me = this;
    var loginInformation = getStorage("loginInformation");
    function success(data) {
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            me.getUserInfo();
        }
        if (!$.isEmptyObject(data.url)) {
            if (data.method == 'GET') {
                window.location.href = data.url;
                return;
            } else {
                $(view('partials/redirect-post', { data: data.data || {}, url: data.url })).appendTo('body')[0].submit();
            }
        } else if (!$.isEmptyObject(data.scriptSrc)) {
            use(fileUrl(data.scriptSrc));
        }
        else {
            if (isManual) {
                dataActive.pageReload();
            } else {
                process.checkTheBook();
            }
        }
    }

    function error(data) {
        if (data.status == 461) {
            swal({
                text: trans(data.responseJSON.message),
                icon: "error",
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: trans('Search again'),
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                    confirm: {
                        text: trans('Continue to pay'),
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            })
                .then((response) => {
                    if (response == true) {
                        $form.find('.pay-button').click();
                    } else {
                        dataActive.pageRoute("index");
                    }
                });
        } 
        else if(data.status == 480) {
            swal({
                text: trans(data.responseJSON.message),
                icon: "error",
                dangerMode: true,
                buttons: {
                    confirm: {
                        text: trans('Ok'),
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                },
                allowOutsideClick: true
            })
            .then((response) => {
                process.searchAgainOrRedirectToHomePage();
            });
        }
        else {
            dataActive.defaultAjaxError(data);
        }
        if (!isManual) {
            process.showCountDown(process.bookingInformation);
            process.onHoldOrIssueBtn.each((index, item) => {
                $(item).prop('disabled', false);
            });
        }
        process.payBtn.text(trans('Book_Pay_Now'));
        process.payBtn.removeClass('spinner');
        process.payBtn.prop('disabled', false);
        if ((process.gatewayItem).length > 1) {
            process.gatewayItem.each((index, item) => {
                $(item).prop('disabled', false);
            });
        } else {
            process.gatewayItem.prop('disabled', false)
        }
    }


    this.getUserInfo = function () {
        var token = loginInformation.token;
        ajax({
            url: dataActive.location.marketplace + "/v1/user/info",
            method: 'get',
            data: {
                api_token: token,
            },
            headers: { 'Authorization': 'Bearer ' + token },
            success: function (data) {
                setStorage("loginInformation", data);
            },
            error: function () {
                console.log('We could not update the user information.');
            }
        });
    }

    this.ajaxParams = function () {
        var loginInformation = getStorage("loginInformation");
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
        }
        return {
            url: dataActive.location.marketplace + "/v1/gateway/initial",
            headers: headers || {},
            method: "post",
            data: $form.serialize(),
            success: success,
            error: error,
        };
    }
}